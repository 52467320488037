<template>
  <div>
    <!-- Contenido principal, con video incrustado como animación -->
    <transition name="fade">
      <div
        v-if="isVisible"
        class="fotoportada-container animate__animated animate__fadeIn"
      >
        <video 
          src="@/assets/video.webm" 
          autoplay 
          loop 
          muted 
          playsinline 
          class="fotoportada-img"
        />

        <!-- Opcional: Contenido superpuesto -->
       
      </div>
    </transition>
  
    <!-- Cuenta regresiva -->
    <div class="countdown-container">
           <p class="centered-text h1">Andrea &  Gilberto</p> 
      <img
        :src="require('@/assets/separador1.png')"
        alt="Separador"
        class="separador-img"
      >
      <h1 class="textfaltan">
        {{ numero }} {{ fechaevento }}
      </h1>
      <div
        ref="textRef"
        class="textfaltan2"
      >
        Nuestra boda es en: <br>
      </div>
      <transition name="fade">
        <div
          v-if="isVisible"
          class="countdown animate__animated animate__fadeInUp"
        >
          <div class="countdown-item">
            <span>{{ countdownText.substring(0, 2) }}</span>
            <label>Días</label>
          </div>
          <div class="countdown-item">
            <span>{{ countdownText.substring(3, 5) }}</span>
            <label>Horas</label>
          </div>
          <div class="countdown-item">
            <span>{{ countdownText.substring(6, 8) }}</span>
            <label>Minutos</label>
          </div>
          <div class="countdown-item">
            <span>{{ countdownText.substring(9, 11) }}</span>
            <label>Segundos</label>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>
<script>
import { ref, onMounted, onBeforeUnmount } from 'vue';

export default {
  name: 'NavbarComponent',
  setup() {
    const countdownText = ref('00:00:00:00');
    const fechaevento = ref('Marzo 2025');
    const numero = ref(1);
    const isVisible = ref(true); // Definimos isVisible para controlar la visibilidad del video
    let countdownInterval;

    // Función para actualizar la cuenta regresiva
    const updateCountdown = () => {
      const endDate = new Date('2025-03-08T16:00:00');
      const now = new Date();
      const difference = endDate - now;
      if (difference <= 0) {
        clearInterval(countdownInterval);
        countdownText.value = '00:00:00:00';
        return;
      }

      const days = Math.floor(difference / (1000 * 60 * 60 * 24));
      const hours = Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((difference % (1000 * 60)) / 1000);

      countdownText.value = `${String(days).padStart(2, '0')} ${String(hours).padStart(2, '0')} ${String(minutes).padStart(2, '0')} ${String(seconds).padStart(2, '0')}`;
    };

    // Lógica para la cuenta atrás y el incremento de número
    onMounted(() => {
      updateCountdown();
      countdownInterval = setInterval(updateCountdown, 1000);

      const target = 8; // Número objetivo que queremos alcanzar
      const interval = setInterval(() => {
        if (numero.value < target) {
          numero.value++; // Incrementa el número en 1
        } else {
          clearInterval(interval); // Detiene el intervalo cuando alcanza el objetivo
        }
      }, 100);
    });

    onBeforeUnmount(() => {
      clearInterval(countdownInterval);
    });

    return {
      numero,
      fechaevento,
      countdownText,
      isVisible, // Asegúrate de que isVisible esté disponible en el template
    };
  },
};
</script>



<style scoped>
/* Barra de navegación */
body {
  margin: 0;
  padding: 0;
}

/* Texto animado */
.animated-text {
  flex-grow: 1;
  text-align: center;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 5s ease;
}

.fade-enter, .fade-leave-to /* .fade-leave-active en Vue <2.1.8 */ {
  opacity: 0;
}

.animated-text p {
  font-family: 'Dancing Script', cursive;
  font-size: 24px;
  color: #4a3f3fef;
  animation: scaleText 1s infinite alternate;
}

@keyframes scaleText {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.5);
  }
}





.fotoportada-container {
  position: relative;
  width: 100%;
  min-height: calc(60vh - 60px); /* Resta la altura de la barra de navegación */
  overflow: hidden;
  margin-top: -58px; /* Ajusta según la altura de la barra de navegación */

  /* Degradado rosado en la parte inferior */
 
  background-size: cover; /* Cubre todo el contenedor */
  background-repeat: no-repeat; /* No repetir la imagen */
  background-position: top center; /* Alinea la imagen a la parte superior y centrada horizontalmente */
  z-index: 1; /* Asegúrate de que esté detrás del contenido superpuesto */
}

.fotoportada-img {
  width: 100%;
  height: 80%; /* Asegura que la imagen ocupe todo el contenedor */
  display: block;
}


  /* Animación de acercamiento */
@keyframes popupFadeIn {
  0% {
    opacity: 0;
    transform: scale(0.8); /* Comienza más pequeño */
  }
  100% {
    opacity: 1;
    transform: scale(1); /* Termina en su tamaño original */
  }
}
.overlay {
  position: absolute; /* Cambiado a absolute para cubrir toda la imagen */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; /* Centra el contenido en el medio */
  color: white;
  z-index: 2;
  padding-top:10px; /* Añade padding superior */
}


.fotoPortada {
  width: 100%;
  height: 100%;
}

@font-face {
  font-family: 'Daydream';
  src: url('../assets/fonts/daydream.ttf') format('truetype');  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Karla';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/karla/v31/qkBIXvYC6trAT55ZBi1ueQVIjQTDeJqaE0lK.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
.centered-text {
  position: relative;
  font-size: 30px; /* Asegúrate de que este tamaño sea adecuado para pantallas grandes */
  font-weight: 400;
  margin-top:80px;
  text-align: center;
  margin-bottom: 0px;
  font-family: 'FeelingPassionate', sans-serif; /* Asegúrate de que la fuente esté cargada */
  background: rgb(101, 96, 96);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent; /* Hacer el texto transparente para aplicar el fondo */
  line-height: 2; /* Ajusta la altura de la línea para evitar que las letras se corten */
  white-space: nowrap; /* Evita que el texto se divida en varias líneas */
  animation: fadeInUp 1s ease forwards, animateText 3s linear infinite; /* Añadimos ambas animaciones */
  opacity: 0; /* Inicialmente invisible */
  transform: translateY(80px); /* Iniciar desplazamiento desde abajo */
  animation-delay: 1.3s; /* Retraso para asegurar que la animación comience con un pequeño retraso */

}


@keyframes animateText {
  0% {
    background-position: 200% 0;
  }
  100% {
    background-position: 0 0;
  }
}


.text {
  font-size: 18px;
  text-align: center;
  color: rgba(255, 255, 255, 0.95);
  margin: 0 10px; /* Ajusta el espaciado entre la línea y el texto */
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  font-family: 'Karla'; /* Cambia la familia de fuentes según tu necesidad */
  opacity: 0; /* Para que empiece invisible */
  transform: translateY(80px);
  animation: fadeInUp 1s ease forwards; /* Controla la duración y suavidad */
  animation-delay: 0.5s; /* Retraso de 0.2 segundos para asegurar visibilidad */
}

.line {
  content: " ";
  display: block;
  width: 40px; /* Ajusta el largo de la línea */
  height: 2px; /* Grosor de la línea */
  background-color: #ebe5e5a1; /* Color de la línea */
   /* Añadir la animación aquí */
   opacity: 0; /* Para que empiece invisible */
  transform: translateY(80px);
  animation: fadeInUp 1s ease forwards; /* Controla la duración y suavidad */
  animation-delay: 0.5s; /* Retraso de 0.2 segundos para asegurar visibilidad */

}

.centered-fechatext {
  font-size: 10px;
  font-weight: 300;
  text-align: center;
  letter-spacing: 2px;
  color: rgba(237, 230, 230, 0.934); /* Color del texto */
  margin-top: -10; /* Asegúrate de que no haya margen superior */
  font-family: 'karla';
  letter-spacing: 2px;
  /* Estilos de fondo */
  padding-left: 0;
  padding-right: 0;
  background-color: rgba(83, 72, 72, 0.5); /* Fondo semitransparente */
  border-radius: 8px; /* Bordes redondeados */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3); /* Sombra para profundidad */
  max-width: 80%; /* Ancho máximo para que no ocupe toda la pantalla */
}


.countdown-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
margin-top:-80px;
  align-items: center;
  /* Imagen de fondo */
  background: linear-gradient(to bottom, #ffffff, #e9e2dcb5);
  background-size: cover;
  padding: 0 20px; /* Agregar padding para evitar que el contenido esté pegado a los bordes */
  width: 100%; /* Ocupará todo el ancho disponible */
  box-sizing: border-box; /* Asegúrate de que el padding no afecte el ancho total */
}

.countdown {
  display: flex;
  gap: 10px;
  font-size: 16px;
  color: rgb(23, 22, 22);
  margin-left: 0; /* Elimina el margen izquierdo */
  margin-right: 0; /* Elimina el margen derecho */
  opacity: 0; /* Comienza oculto */
  transition: opacity 0.3s ease;
  border-radius: 12px; /* Bordes redondeados */
  padding: 10px; /* Espaciado interno */
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2); /* Sombra para profundidad */
}

.countdown-item {
  border-radius: 6px;
  padding: 8px 12px;
  text-align: center;
  background: rgba(255, 255, 255, 0.8); /* Fondo blanco semi-transparente */
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); /* Sombra para el elemento */
}

.countdown-item span {
  display: block;
  font-size: 16px;
  font-family: 'Noto Serif', serif;
  font-weight: 100; /* Asegúrate de que el peso coincida con la fuente seleccionada */
}

.countdown-item label {
  font-size: 16px;
  color: rgba(15, 14, 14, 0.8);
  font-family: 'Dancing Script', cursive;
}









  .animated-text p {
    font-size: 20px; /* Reduce el tamaño del texto en pantallas pequeñas */
  padding-left: 0;
  padding-right: 0;

  margin: 0;

  display: flex;

}
.animated-text {
  display: flex; /* Asegúrate de que el contenedor tenga display: flex */
  justify-content: center; /* Centra el contenido horizontalmente */
  align-items: center; /* Centra el contenido verticalmente */
  text-align: center; /* Asegúrate de que el texto esté centrado */
}



.animate__fadeInUp {
  animation: fadeInUp 0.5s forwards; /* Cambia la duración como necesites */
}

@keyframes fadeInUp {
  from {
    transform: translateY(20px); /* Comienza 20px más abajo */
    opacity: 0; /* Comienza oculto */
  }
  to {
    transform: translateY(0); /* Vuelve a su posición original */
    opacity: 1; /* Se hace visible */
  }
}

.fade-leave-active {
  opacity: 0; /* Se desvanecerá */
  transition: opacity 0.3s ease;
}




/* Animación de entrada desde abajo */
.fade-in-up {
  opacity: 0;
  transform: translateY(20px);
  animation: fadeInUp 2s ease forwards; /* Duración y suavizado */
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(20px); /* Empieza desplazado 20px hacia abajo */
  }
  100% {
    opacity: 1;
    transform: translateY(0); /* Llega a la posición final */
  }
}
.superposed-image {
  position: absolute; /* Mantiene la imagen posicionada absolutamente dentro del contenedor */
  top: 75%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 500px;
  z-index: 5;
  
}

.textfaltan2 {
  font-family: 'Dancing Script', cursive;
  font-size: 24px;
  font-weight: 800;
  margin-bottom: 10px;
  margin-top: 10px;
  background: linear-gradient(270deg, #2e2e2c, #68685bea, #666b58e8);
  background-size: 200% 100%;
  background-position: 0 0;
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  animation: gradientAnimation 5s ease infinite; /* Animación */
}
.textfaltan {
  font-size: 24px;
  font-weight: 100;
  font-family: 'Karla';

  margin-top: 30px;
  background: linear-gradient(270deg, #313131, #181817, #20201f);
  background-size: 200% 100%;
  background-position: 0 0;
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  animation: gradientAnimation 5s ease infinite; /* Animación */
}

@keyframes gradientAnimation {
  0% {
    background-position: 200% 0; /* Inicia desde la derecha */
  }
  100% {
    background-position: 0 0; /* Termina a la izquierda */
  }}
  .animate-slideInLeft {
  animation: slideInLeft 1s ease-out;
}



.separador-img {
  width: 100%;
  max-width: 50%; /* Cambia esto para hacerla más pequeña */
  height: auto;
  display: block;
  margin-top: 20px;
}


/* Loader */
.loader-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: #ffffff; /* Fondo blanco */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.loader-image {
  width: 100px;
  height: auto;
  animation: pulse 1.5s infinite ease-in-out; /* Efecto de pulso */
}

/* Efecto de pulso */
@keyframes pulse {
  0%, 100% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(1.2);
    opacity: 0.7;
  }
}

</style>