<template>
  <div>
    <!-- Contenedor Mensaje de Bienvenida -->
    <div
      ref="bienvenidaRef"
      class="bienvenida-container"
    >
      <img
        src="@/assets/separador1.png"
        alt="Decoración SVG"
        class="separador-img"
      >
      <div
        ref="mensajeRef"
        class="mensaje-bienvenida"
      >
        {{ mensaje }}
      </div>

      <!-- Nuevo texto adicional -->
      <div class="nuevo-texto">
        <br> Andrea & Gilberto
      </div>

      <!-- Ícono entre los dos textos -->
      <div class="icono-container">
        <i class="fas fa-heart icono" />
      </div>
    </div>

    <!-- Resto del contenido... -->
  </div>
</template>

<script>
import { onMounted, ref, nextTick } from 'vue';
import 'animate.css'; // Asegúrate de importar animate.css

export default {
  name: 'BienvenidaPage',
  setup() {
    const bienvenidaRef = ref(null);
    const animationDone = ref(false);
    const mensaje = ref(""); // Mensaje que se mostrará
    const text = `
"El amor es sufrido, es benigno;
el amor no tiene envidia,
el amor no es jactancioso, no se envanece;
no hace nada indebido, no busca lo suyo,
no se irrita, no guarda rencor;
no se goza de la injusticia, mas se goza de la verdad.
Todo lo sufre, todo lo cree,
todo lo espera, todo lo soporta." 
— Corintios 13:4-7

¡Te esperamos para celebrar el comienzo de nuestra eternidad juntos!
`;
    let hasStartedWriting = false; // Para controlar si la escritura ya comenzó

    const escribirTexto = (texto, index) => {
      if (index < texto.length) {
        mensaje.value += texto.charAt(index);
        setTimeout(() => escribirTexto(texto, index + 1), 40); // Cambia el número para ajustar la velocidad
      }
    };

    onMounted(async () => {
      await nextTick();

      if (bienvenidaRef.value) {
        const element = bienvenidaRef.value;

        const observerOptions = {
          root: null,
          rootMargin: '0px 0px -20% 0px', // Permite que el elemento comience a animarse un poco después de ser visible

          threshold: [0.3]
        };

        const observerCallback = (entries) => {
          entries.forEach(entry => {
            if (entry.isIntersecting) {
              if (!animationDone.value) {
                element.classList.add('animate__animated', 'animate__fadeInUp');
                element.addEventListener('animationend', () => {
                  element.classList.remove('animate__animated', 'animate__fadeInUp');
                  element.style.opacity = '1';
                }, { once: true });

                // Iniciar la escritura solo si no ha comenzado antes
                if (!hasStartedWriting) {
                  escribirTexto(text, 0);
                  hasStartedWriting = true; // Marcar que ya se inició la escritura
                }

                animationDone.value = true;
              }
            } else {
              element.style.opacity = '0';
              animationDone.value = false;
            }
          });
        };

        const observer = new IntersectionObserver(observerCallback, observerOptions);
        observer.observe(element);
      }
    });

    return { bienvenidaRef, mensaje };
  }
};
</script>

<style scoped>
/* Contenedor Mensaje de Bienvenida */
.bienvenida-container {
  position: relative;
  width: 100%;
  padding-top: 0px;
  margin: 20;
  text-align: center;
  background: linear-gradient(to bottom, #f4f4f4 0%, #ffffff 50%, #f4f4f4 100%); /* Degradado de gris claro a blanco y de vuelta a gris claro */
  opacity: 0;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 20px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  transition: transform 0.5s;
}

.bienvenida-container:hover {
  transform: translateY(-5px);
}

.mensaje-bienvenida {
  font-size: 18px; /* Ajustar tamaño del texto */
  color: rgba(75, 67, 63, 0.553);
  font-family: 'Jost', sans-serif;
    
  margin: 0;
  font-weight: 400;
  white-space: pre-line; /* Respeta los saltos de línea */

  transition: color 0.3s;
  padding: 10px 20px; /* Añadir espacio interno (top/bottom, left/right) */

}

/* Estilos para el nuevo texto adicional */
.nuevo-texto {
  font-size: 30px;
  font-family: 'FeelingPassionate';
  opacity: 1;
  line-height: 1.9; /* Ajusta la separación entre líneas */
  white-space: normal; /* Permite que el texto se ajuste a múltiples líneas */
  word-break: break-word; /* Asegura que el texto largo se ajuste en caso de ser necesario */  font-weight: 400;
  color: #574b3ed4;
  margin-bottom: 20px; /* Espacio entre el nuevo texto y el ícono */
  text-align: center; /* Centrar el texto */
  transition: color 0.3s ease-in-out;
  margin-bottom: 0px;
}

.nuevo-texto:hover {
  color:  #917087d4; /* Cambia a un tono más suave al pasar el ratón */
}

.mensaje-bienvenida:hover {
  color: rgba(122, 101, 97, 0.8);
}

/* Contenedor del ícono */
.icono-container {
  margin: 5px 0;
}

/* Estilos para el ícono */
.icono {
  font-size: 18px;
  color: #1d1c1c00; 
  animation: pulsar 1.5s infinite;
}

/* Animación para el ícono */
@keyframes pulsar {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}

/* Estilos para el texto adicional */
.texto-adicional {
  font-size: 24px;
  font-weight: 300;
  font-family: 'Caveat', cursive;
  margin-top: 20px;
}

/* Estilos para el contenedor de mensajes */
.mensaje-container {
  margin-top: 10px;
  padding: 20px;

  border: 1px solid rgba(101, 93, 89, 0.7);
  border-radius: 10px;
  background-color: rgba(255, 255, 255, 0.8);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s;
}

.mensaje-container:hover {
  transform: scale(1.02);
}

.mensaje-container h2 {
  margin-bottom: 10px;
}

.mensaje-container textarea {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid rgba(52, 46, 44, 0.5);
  border-radius: 5px;
}

/* Estilos para el campo de nombre */
.nombre-input {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid rgba(52, 46, 44, 0.5);
  border-radius: 5px;
}

/* Estilos para el botón */
.mensaje-container button {
  padding: 10px 20px;
  background-color: rgba(114, 104, 97, 0.8);
  border: none;
  border-radius: 5px;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;
}

.mensaje-container button:hover {
  background-color: rgb(101, 87, 87);
  transform: scale(1.05);
}

/* Estilos para los mensajes enviados como tarjetas */
.mensajes-enviados {
  margin-top: 20px;
  text-align: left;
}

.mensajes-enviados h3 {
  margin-bottom: 10px;
}

/* Centrar la imagen separador */
.separador-img {
  width: 100%;
  max-width: 50%; /* Cambia esto para hacerla más pequeña */
  height: auto;
  display: block;
  margin-top: 20px;
}

/* Estilo para cada tarjeta de mensaje */
.mensaje-tarjeta {
  padding: 10px;
  margin: 5px 0;
  background: rgba(255, 255, 255, 0.9);
  border-radius: 5px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s, box-shadow 0.3s;
}

.mensaje-tarjeta:hover {
  transform: translateY(-5px) rotateY(5deg);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
}
</style>

