<template>
  <div class="full-width-image">
    <!-- Imagen de fondo -->
    <img
      src="../assets/imagenprimera.png"
      alt="Descripción de la imagen"
    >
    
    <!-- SVG decorativo posicionado en la parte inferior -->
  </div>
</template>

<script>
export default {
  name: 'FullWidthImage',
};
</script>

<style scoped>
/* Elimina el margen y el padding del body para evitar barras de desplazamiento */
body {
  margin: 0;
  padding: 0;
  overflow-x: hidden; /* Evita que aparezca la barra de desplazamiento horizontal */
}

/* Contenedor de la imagen */
.full-width-image {
  width: 100%;
  text-align: center;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  background-image: url('@/assets/i3.png');

  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  position: relative; /* Es necesario para posicionar el SVG sobre la imagen */
  overflow: hidden; /* Oculta cualquier contenido que exceda el contenedor */
}

.full-width-image img {
  width: 100%;
  height: auto; 
  display: block; 
  filter: brightness(80%); /* Reduce el brillo al 50% */
}

/* Estilo para el SVG decorativo */

</style>
