<template>
  <div class="timeline-container">
    <!-- Overlay -->
    <div class="overlay">
      <div class="overlay-text" />
      <div class="text2 stylish-text">
        Hospedaje
      </div>
      <!-- Columna izquierda con icono y texto -->
      <div
        ref="leftRef"
        class="timeline-left"
      >
        <div>
          <img
            :src="require('@/assets/hospedaje.jpg')"
            alt="Globo"
            class="rounded-circle"
          >
        </div>
          
        <div class="text">
          <br>   HOTEL JM EJECUTIVO CELAYA
        </div>
        <div class="text2">
          Te compartimos esta sugerencia de hospedaje.  <br><br><strong> Dirección:</strong>  <br> Av. Lázaro Cárdenas No. 705  <br>Col. Santa María <br> Celaya, Guanajuato.   <br>  
          <strong><br> Código de descuento:<br>  Tarifa boda andrea y gilberto 08-03-2025
          </strong>
          <link
            rel="stylesheet"
            href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0-beta3/css/all.min.css"
          >

          <!-- Código HTML -->
          <p>
            <i class="fas fa-phone" />  461-172-08-88
          </p>
        </div>
        <a
          href="https://maps.app.goo.gl/mdaN6JoHQtdeYUEJ6"
          class="modern-button"
          target="_blank"
          rel="noopener noreferrer"
        ><i class="fas fa-map-marker-alt icono" />
  
          Ubicación</a>
      </div>
    </div>
  </div>
</template>
      
    <script>
    import { onMounted, ref, nextTick } from 'vue';
    
    export default {
      name: 'TimelineComponent',
      setup() {
        const leftRef = ref(null);
        const rightRef = ref(null);
    
        onMounted(() => {
          nextTick(() => {
            const elements = [leftRef.value, rightRef.value];
    
            const observerOptions = {
              root: null,
              threshold: 0.1,
            };
    
            const observerCallback = (entries) => {
              entries.forEach((entry) => {
                if (entry.isIntersecting) {
                  entry.target.classList.add('animate-fadeInUp');
                  entry.target.addEventListener(
                    'animationend',
                    () => {
                      entry.target.classList.remove('animate-fadeInUp');
                    },
                    { once: true }
                  );
                }
              });
            };
    
            const observer = new IntersectionObserver(observerCallback, observerOptions);
    
            elements.forEach((element) => {
              if (element) {
                observer.observe(element);
              }
            });
          });
        });
    
        return {
          leftRef,
          rightRef,
        };
      },
    };
    </script>
      
    <style scoped>
    /* Contenedor general del timeline */
    .timeline-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      background-image: url('@/assets/image3.png'); /* Reemplaza con la ruta correcta de tu imagen */
  background-size: cover; /* Asegura que la imagen cubra el contenedor sin distorsionarse */
  background-position: center center; /* Centra la imagen */
      align-items: center;
      position: relative;
      min-height: 110vh; /* Altura mínima para cubrir la ventana gráfica */
      padding: 0; /* Añade padding horizontal para evitar el desbordamiento */
      box-sizing: border-box;
      overflow-x: hidden; /* Evita el desplazamiento horizontal */
      margin: 0;
      background-position: center; /* Centra la imagen */
      background-repeat: no-repeat; /* Evita que la imagen se repita */
      background-size: cover; /* Asegura que la imagen cubra todo el contenedor */
      z-index: 0;
  }
  
  
    /* Línea vertical */
    .timeline-line {
      width: 2px;
      position: absolute;
      top: 0;
      background: linear-gradient(to bottom, #ffffff, #f4f4f4, #ffffff);

      left: 50%;
      transform: translateX(-50%);
      box-sizing: border-box; /* Incluye el borde en el ancho total */
      z-index: -2; /* Asegúrate de que el z-index sea menor que el de los otros elementos */
      pointer-events: none; /* Esto hará que la línea vertical no reciba eventos de puntero */
  
    }
    
    /* Columnas para iconos y texto */
    .timeline-left,
    .timeline-right {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      background: linear-gradient(to bottom, #ffffff, #f4f4f4, #ffffff);

      max-width: 300px; /* Ajusta el ancho máximo para evitar que las columnas se hagan demasiado grandes */
      padding: 10px;
      box-sizing: border-box; /* Incluye el relleno en el ancho total */
      z-index: 10; /* Asegúrate de que el z-index sea menor que el de los otros elementos */
  
    }
    
    /* Icono */
    .icon {
      
      margin-bottom: 10px;
      margin-top: 10px;
  
    }
    
    /* Icono de imagen */
    .icon-image {
      width: 80%;
      max-width: 140px; /* Limita el ancho máximo de la imagen */
      height: auto; /* Mantiene la proporción de la imagen */
      animation: pulse 2s infinite; /* Duración de 2 segundos y repetición infinita */
    }
    
    /* Texto */
    .text {
      font-size: 16px;
      text-align: center;
      color: #1f1c1ccd;
      font-family: "Oswald", Sans-serif;
      font-weight: 600;
    }
    
    .text2 {
      font-size: 16px;
      text-align: center;
      color: rgba(20, 19, 19, 0.9);
      font-family: 'Josefin Sans', sans-serif;
      font-weight: 300;
      margin-top: 20px;
      margin-bottom: 10px; /* Espacio inferior */
      
      margin-left: 25x; /* Ajusta el valor según sea necesario */
      margin-right: 15px; /* Ajusta el valor según sea necesario */
  
    }
    .text3 {
      font-size: 12px;
      text-align: center;
      color: rgba(46, 44, 44, 0.9);
      font-family: 'Josefin Sans', sans-serif;
      font-weight: 300;
      margin-top: 10px;
      margin-bottom: 10px; /* Espacio inferior */
      font-weight: 600;
  
    }
    
    /* Animaciones */
    @keyframes chicoagrande {
    0% {
      transform: scale(0.5); /* Tamaño inicial */
    }
  
    100% {
      transform: scale(1); /* Volver a tamaño original */
    }
  }
  
    
    .animate-fadeInUp {
      animation: chicoagrande 1s ease-out forwards;
  }
    
    .overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 90%;
      height: 50%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start; /* Alinea los elementos desde la parte superior */
      align-items: center; /* Centra horizontalmente los elementos */
      background: linear-gradient(to bottom, #ffffff, #f4f4f4, #ffffff);
      border-radius: 0.2rem; /* Esquinas redondeadas */
      padding: 1rem; /* Espaciado interno del contenedor */
      position: relative; /* Asegura que los elementos hijos se posicionen en relación a este contenedor */
      z-index: 1; /* Asegura que esté sobre la línea */    z-index: 2;
      padding: 0px 20px 20px,0px; /* Aumenta el padding superior para mover todo el contenido hacia abajo */
      text-align: center;
      overflow: visible; /* Asegura que el contenido visible se muestre correctamente */
  }
    
    /* Texto del overlay */
    .overlay-text {
      font-size: 20px;
      font-family: 'Dancing Script', cursive;
      font-weight: 600;
      margin: 40px;
    }
    
   
    
    
    /* Animación para aumentar y disminuir tamaño */
    @keyframes pulse {
      0% {
        transform: scale(1);
      }
      50% {
        transform: scale(1.1); /* Aumenta el tamaño al 110% */
      }
      100% {
        transform: scale(1);
      }
    }
   /* Alternativa para el texto "¿Dónde y Cuándo?" */
  .stylish-text {
    font-size: 38px;
    letter-spacing: 5px; /* Espaciado entre letras */
  
    font-family: 'Great Vibes', cursive !important;
    font-weight: 800;
    margin: 0 30px;
    background: linear-gradient(270deg, #9c916b,  #121212, #5c5640);
    background-size: 200% 100%;
    background-clip: text;
    color: transparent;
    margin-top: -80px;
    animation: animateText 5s linear infinite;
  }
  @keyframes animateText {
    0% {
      background-position: 200% 0;
    }
    100% {
      background-position: 0 0;
    }
  }
  
  /* Alternativa con un fondo degradado */
  
  
  /* Estilo para botones modernos */
  .modern-button {
    display: inline-block;
    text-decoration: none;
    font-size: 16px;
    background-color: #46412fda; 
    color: #fff; 
    border: 2px solid #3c3e31;
    font-family: 'Roboto', cursive;
    padding: 12px 24px;
    border: none;
    border-radius: 25px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    transition: transform 0.2s ease, background 0.5s ease;
    margin-bottom: 15px;
  }
  
  
  .rounded-circle {
  
    top: 20px; /* Ajusta este valor para bajarlo más */
    width: 150px; /* Ajusta el tamaño del círculo como prefieras */
    height: 150px;
    border-radius: 50% !important;
    object-fit: cover; /* Hace que la imagen se recorte en el círculo */
    overflow: hidden; /* Asegura que la imagen no se salga del círculo */
    outline: 3px solid #9c916b;
  }
  
  .modern-button:focus {
    outline: none;
    box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.2);
  }
  
    </style>
    