<template>
  <div>
    <!-- Contenedor Bienvenida -->
    <div
      ref="bienvenidaRef"
      class="bienvenida-container"
    >
      <p 
        class="bienvenida-text animated-slide-up"
      >
        {{ mensaje }}
      </p>

      <p
        v-if="mostrarMensajeFinal"
        class="textmia animated-slide-up"
      >
        {{ mensajeFinal }}
      </p>
      <img
        :src="separadorImg"
        alt="Separador"
        class="separador-img animated-slide-up"
      >
    </div>
  </div>
</template>

<script>
import {  ref,  } from 'vue';
import separadorImg from '@/assets/separador1.png';

export default {
  name: 'BienvenidaPage',
  setup() {
    const bienvenidaRef = ref(null);
    const mensaje = ref(
      " \n Una Boda se vive tres veces: al soñarla, al celebrarla y al recordarla. \n\n Nos encantaría que lo celebres con nosotros y seas parte de nuestros recuerdos."
    );
    const mensajeFinal = ref("");
    const mostrarMensajeFinal = ref(true);

    return {
      bienvenidaRef,
      mensaje,
      mensajeFinal,
      mostrarMensajeFinal,
      separadorImg,
    };
  },
};
</script>

<style scoped>
/* Contenedor Bienvenida */
@font-face {
  font-family: 'Karla';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/karla/v31/qkBIXvYC6trAT55ZBi1ueQVIjQTDeJqaE0lK.woff2) format('woff2');
}

.bienvenida-container {
  position: relative;
  width: 100%;
  margin-top: 30px;
  margin: 0;
  text-align: center;
  padding: 20px;
  background-color: #f0f0f0;
  background: linear-gradient(to top, #ffffff, #e9e2dcb5);
  background-size: cover;
  background-position: center;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Estilos para el texto de bienvenida */
.bienvenida-text {
  font-size: 18px;
  color: rgba(75, 67, 63, 0.553);
  font-family: 'Jost', sans-serif;
  margin: 0 20px 40px 20px;
  white-space: pre-line;
}

@font-face {
  font-family: 'FeelingPassionate';
  src: url('@/assets/fonts/passionate.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

.textmia {
  font-family: 'FeelingPassionate';
  font-size: 34px;
  opacity: 1;
  color: #534b44d4;
  line-height: 1.9;
  white-space: normal;
  word-break: break-word;
  margin-top: 10px;
}

.separador-img {
  width: 100%;
  max-width: 50%;
  height: auto;
  display: block;
  margin: 0 auto;
}

/* Animación para el desplazamiento de abajo hacia arriba */
@keyframes slide-up {
  from {
    transform: translateY(20px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.animated-slide-up {
  animation: slide-up 0.6s ease-out;
}
</style>
